import commonConfig from "config/commonConfig";
import React from "react";
import { Card, CardContent } from "@material-ui/core/index";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { copyToClipboard } from "./APIKeyGenerator";
import { userDetails } from "@ui/Utils/helper";

const api = {
  name: "Bulk Image Upload",
  //   description:
  // "Upload Default Images of Designs in Bulk by having image file names with DesignNumber.png for example.",
  endpoint: `${commonConfig.baseApiUrl}designs/bulkImageUpsert`,
  method: "POST",
  contentType: "multipart/form-data",
  validation: "MAX 5mb images per API chunk for multiple images.",
  requestBody: {
    files: ["Binary"],
  },
  curl: `curl --location '${commonConfig.baseApiUrl}designs/bulkImageUpsert' \
            --header 'x-api-key;' \
            --header 'x-source;' \
            --header 'Content-Type: multipart/form-data' \
            --form 'files=@"/path/to/file"'`,
};

const BulkImageUpload = () => {
  return (
    <div className="p-4 border rounded-md bg-gray-100">
      <h2 className="text-xl font-bold">Bulk Image Upload</h2>
      <p className="mt-2">
        The Bulk Image Upload API allows you to upload default images of designs
        in bulk. Each image file should be named according to the Design Number
        (e.g., <code>DesignNumber.png</code>) to ensure correct mapping with the
        respective design in the system.
      </p>

      <Card
        style={{ marginBottom: "12px" }}
        className="mb-6 border border-gray-700 bg-gray-800 p-4 rounded-lg"
      >
        <CardContent>
          {/* <h2 className="text-xl font-semibold text-blue-400">{api.name}</h2>
          <p className="text-gray-300">{api.description}</p> */}

          <div className="mt-3 flex items-center">
            <span className="font-medium text-gray-400">🔗 Endpoint:</span>
            <pre className="ml-2 bg-gray-700 text-gray-300 p-2 rounded-lg">
              {api.endpoint}{" "}
              <MyHoverIcons
                muiIcon={<FileCopyOutlinedIcon />}
                muiIconHover={<FileCopyIcon />}
                tooltip={"Copy"}
                onClick={() => copyToClipboard(api.endpoint)}
              />
            </pre>
          </div>

          <div className="mt-3">
            <span className="font-medium text-gray-400">🔴 Use Case: </span>
            <span className="ml-2 font-semibold text-yellow-400">
              {api.validation}
            </span>
          </div>

          <div className="mt-3">
            <span className="font-medium text-gray-400">🟢 Method: </span>
            <span className="ml-2 font-semibold text-yellow-400">
              {api.method}
            </span>
          </div>

          <div className="mt-3">
            <span className="font-medium text-gray-400">𝌞 Content Type: </span>
            <span className="ml-2 font-semibold text-yellow-400">
              {api.contentType}
            </span>
          </div>

          {/* Request Body */}
          <div className="mt-3">
            <div>
              <span className="font-medium text-gray-400">
                📌 Request Body:
              </span>
              <pre className="mt-2 bg-gray-700 text-gray-300 p-3 rounded-lg text-sm">
                {JSON.stringify(api.requestBody, null, 2)}
              </pre>
            </div>
          </div>

          <div>
            <div>
              <span className="font-medium text-gray-400">🕸️ CURL:</span>

              <div style={{ wordBreak: "break-all" }}>{api.curl}</div>
              <MyHoverIcons
                muiIcon={<FileCopyOutlinedIcon />}
                muiIconHover={<FileCopyIcon />}
                tooltip={"Copy CURL"}
                onClick={() => copyToClipboard(api.curl)}
              />
            </div>
          </div>

          {/* <div>
              <div>
                <span className="font-medium text-gray-400">🕸️ Response:</span>

                <pre className="mt-2 bg-gray-700 text-gray-300 p-3 rounded-lg text-sm">
                  {JSON.stringify(api.response, null, 2)}
                </pre>
              </div>
            </div> */}
        </CardContent>
      </Card>

      <h3 className="mt-4 font-semibold">Important Rules & Considerations</h3>
      <ul className="mt-2 list-disc pl-4">
        {userDetails.client.premiumClient?.unlimitedBucketStorage ? (
          <li>You can upload either products first or images first.</li>
        ) : (
          <li>
            Products must be uploaded before images upload so that images will
            get Synced with their products.
          </li>
        )}
        {!userDetails.client.premiumClient?.unlimitedBucketStorage ? (
          <li>
            Images will not get uploaded if products don't exist with its
            fileName.
          </li>
        ) : null}
        <li>
          Images are saved with .png extension to maintain uniformity in default
          product images.
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">API Details</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          Method: <strong>POST</strong>
        </li>
        <li>
          Supports multiple image uploads per request, with a maximum of{" "}
          <strong>5MB</strong> per API chunk and each image file under 5MB.
        </li>
        <li>
          If the same file is uploaded again, it will replace the existing image
          for that design.
        </li>
        <li>
          Allowed file types: <strong>JPEG, JPG, PNG</strong>.
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">Headers</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          <code>x-api-key</code>: Required for authentication
        </li>
        <li>
          <code>Content-Type</code>: <code>multipart/form-data</code>
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">Request Body</h3>
      <p className="mt-2">The request body should include:</p>
      <ul className="mt-2 list-disc pl-4">
        <li>
          A <code>FormData</code> object containing the image files.
        </li>
        <li>
          The <code>files</code> field should hold the binary image data.
        </li>
      </ul>

      <h3 className="mt-4 font-semibold">Example (JavaScript FormData)</h3>
      <pre className="bg-gray-200 p-2 rounded mt-2">
        <code>
          {`const formData = new FormData();
formData.append("files", fileInput.files[0]); // Add multiple files if needed`}
        </code>
      </pre>

      <h3 className="mt-4 font-semibold">Response Codes</h3>
      <ul className="mt-2 list-disc pl-4">
        <li>
          <strong>200</strong>: Success
        </li>
        <li>
          <strong>400</strong>: Validation error if the total size of images in
          a single request exceeds 5MB.
        </li>
        <li>
          <strong>429</strong>: Rate limit exceeded (too many requests in a
          short period).
        </li>
        <li>
          <strong>500</strong>: Internal server error. Contact Customer Support.
        </li>
      </ul>

      <h3>Response</h3>
      <pre>
        {`{
            "success": true, // Indicates that the request was successfully processed

            "data": {
                "uploadedFiles": [ // List of files that were successfully uploaded
                {
                    "fileType": "image/png", // The type of uploaded file (PNG in this case)
                    "isProductExist": false, // false means the product for this image doesn't exist in the system
                    "fileName": "imageName.png", // The name of the uploaded file
                    "styleNo": "imageName", // The design number extracted from the filename
                    "mimetype": "image/jpeg", // MIME type of actual file
                    "cortexRun": true // true means the file was processed through the API pipeline
                }
                ],

                "notUploadedFiles": [ // List of files that failed to upload
                {
                    "fileName": "fileName.jpg", // Name of the file that wasn't uploaded
                    "fileType": "image/jpeg", // The type of the file that failed
                    "productNotFound": true // true means no matching product was found for this file
                }
                ],

                "syncedCount": 1, // Number of images successfully synced to its products (1 in this case)
                "unSyncedCount": 1, // Number of images that haven't been synced to products yet (1 in this case)
                "notUploadedCount": 1, // Number of images that are not uploaded (1 in this case)
                "isPremiumClient": true // true means the user is a premium client with additional privileges
            },

            "message": "Files are Uploaded Successfully." // General message indicating the upload process is complete
        }
`}
      </pre>
    </div>
  );
};

export default BulkImageUpload;
